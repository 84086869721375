import http from './http'

export function getCharge(param) {
  return http({
    url: `/api/v2/charges`,
    method: 'get',
    params: param,
  })
}

export function getChargeType() {
  return http({
    url: `/api/v2/chargetype`,
    method: 'get',
  })
}

export function getChargeStat(data) {
  return http({
    url: `/api/v2/charges/stat`,
    method: 'post',
    data,
  })
}
