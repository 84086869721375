<template>
  <div id="arrestTable">
    <v-data-table
      ref="atable"
      :headers="headers"
      :loading="loading"
      :items="lists"
      :options.sync="options"
      :items-per-page.sync="tableMeta.per_page"
      :server-items-length="tableMeta.total"
      :footer-props="footer"
      class="elevation-1 row-pointer"
      @dblclick:row="rowDblClick"
      @contextmenu:row="rightClick"
    >
      <template v-slot:[`item.arrest_at`]="{ item }">
        {{
          moment(item.arrest_at)
            .add(543, 'year')
            .format('D MMMYYYY เวลา HH:mm')
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-speed-dial direction="left" open-on-hover>
          <v-btn color="blue darken-4" icon slot="activator" small dark fab>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-btn x-small fab @click.stop="rowClick(item.id)" color="blue" dark>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn x-small fab @click.stop="$emit('row-dbl-click', item.id)" color="blue" dark v-if="item.can.update">
            <v-icon small>mdi-pen</v-icon>
          </v-btn>
          <v-btn x-small fab @click.stop="removeData(item.id)" color="red" dark v-if="item.can.delete">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small fab color="grey" dark @click="$emit('logs', item.id)" v-on="on" v-bind="attrs">
                <v-icon small>mdi-playlist-check</v-icon>
              </v-btn>
            </template>
            <span>Change Logs</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
    </v-data-table>
    <div id="print" ref="pp" class="d-none"></div>
  </div>
</template>

<script>
import { list } from '@/api/arrestreport'
import { mapGetters } from 'vuex'
export default {
  methods: {
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        keyword: this.keyword,
        type: this.type,
        arrest: this.showArrestOnly,
        warn: this.showWarnOnly,
        start: this.dateStart,
        end: this.dateEnd,
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
      }
      list(param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
          this.$emit('on-list-updated', res.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(id) {
      this.$emit('row-click', id)
    },
    print() {
      // this.$htmlToPaper('arrestTable')
      var x = this.$refs.atable.$children[0].$el.children
      x = x[0]
      this.$refs.pp.innerHTML = x.innerHTML
      this.$htmlToPaper('print')
      console.log(x)
    },
    rightClick(e, data) {
      e.preventDefault()
      this.$emit('row-click', data.item.id)
    },
    rowDblClick(e, data) {
      if (!data.item.can.update) return
      this.$emit('row-dbl-click', data.item.id)
    },
    removeData(id) {
      this.$emit('remove-user', id)
    },
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    showArrestOnly: {},
    showWarnOnly: {},
  },
  watch: {
    options: {
      handler() {
        this.loading = true
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    datesSelected(v) {
      this.getList()
    },
    showArrestOnly() {
      this.getList()
    },
    showWarnOnly() {
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'วันที่ดำเนินการ',
          sortable: false,
          value: 'arrest_at',
        },
        {
          text: 'ดำเนินการ',
          sortable: false,
          value: 'type',
        },
        {
          text: 'ข้อหา',
          value: 'charges',
          sortable: false,
        },
        {
          text: 'จุดตรวจ',
          value: 'checkpoint',
          sortable: false,
        },
        { text: 'สถานี', value: 'station', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
