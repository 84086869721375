<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" max-width="900" @keypress.esc="dialog = false">
      <v-card elevation="3" :loading="loading">
        <v-toolbar dense flat>
          <v-toolbar-title>
            <v-icon>
              mdi-handcuffs
            </v-icon>
            ผลการปฏิบัติ
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid v-if="report != null">
          <v-row>
            <v-col cols="12" md="6">
              <p class="caption mb-0">วันที่</p>
              <span class="subtitle-1">{{ report.arrest_at }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">ข้อหา</p>
              <span class="subtitle-1">{{ report.charges.map(i => i.name).join(', ') }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">ผู้ปฏิบัติ</p>
              <span class="subtitle-1">{{ report.officers.map(i => i.text).join(', ') }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">ด่านตรวจ</p>
              <span class="subtitle-1">{{ report.checkpoint }}</span>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption mb-0">สถานี</p>
              <span class="subtitle-1">{{ report.station }}</span>
            </v-col>
            <v-col cols="12" md="12">
              <p class="caption mb-0">รายละเอียด</p>
              <span class="subtitle-1">{{ report.detail }}</span>
            </v-col>
          </v-row>
        </v-container>

        <v-carousel v-if="report != null && report.images.length > 0">
          <v-carousel-item v-for="(item, i) in report.images" :src="item" :key="i"></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { showReport } from '@/api/arrestreport'
export default {
  methods: {
    getReport(id) {
      this.loading = true
      showReport(id)
        .then(res => {
          this.report = Object.assign({}, res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    open(id) {
      this.getReport(id)
      this.dialog = true
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      report: null,
    }
  },
}
</script>

<style></style>
