<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-handcuffs
          </v-icon>
          รายงานผลการปฏิบัติ
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex">
          <v-btn color="indigo darken-2" dark class="mr-1" @click="newOfficer">
            <v-icon small>
              mdi-plus-box
            </v-icon>
            เพิ่มรายงาน
          </v-btn>
          <v-btn color="indigo darken-2" @click="$refs.otable.print()" class="white--text">
            <v-icon small>
              mdi-printer
            </v-icon>
            Print</v-btn
          >
          <v-spacer></v-spacer>
          <v-switch
            v-model="show1"
            hide-details
            single-line
            :disabled="loading"
            label="แสดงเฉพาะจับกุม"
            inset
            class="mr-4 pt-0"
          ></v-switch>
          <v-switch
            v-model="show2"
            hide-details
            single-line
            :disabled="loading"
            label="แสดงเฉพาะตักเตือน"
            inset
            class="mr-4 pt-0"
          ></v-switch>
        </div>
      </v-card-text>
      <arrest-table
        :keyword="keyword"
        :type="type"
        :show-arrest-only="show1"
        :show-warn-only="show2"
        ref="otable"
        @row-click="showDetail"
        @row-dbl-click="editOfficer"
        @remove-user="deleteOfficer"
        @logs="logs"
        @on-list-updated="updateJson"
      ></arrest-table>
    </v-card>

    <!-- dialog -->
    <arrest-dialog ref="odlg" v-model="userDialog" :type="modify" @inserted="onInserted"></arrest-dialog>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    <log-dlg ref="logdlg" v-model="logDialog"></log-dlg>
    <arrest-show ref="show"></arrest-show>
  </v-container>
</template>

<script>
import arrestTable from './components/arrestTable'
import arrestDialog from './components/arrestDialog'
import arrestShow from './components/arrestShowDialog'
import logDlg from '@/components/activityList'
import { destroy } from '@/api/arrestreport'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.userDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.userDialog = true
    },
    showDetail(id) {
      this.$refs.show.open(id)
    },
    logs(id) {
      this.$refs.logdlg.getLogs('arrestreport', id)
      this.logDialog = true
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.userDialog = false
      this.$refs.otable.getList()
    },
    test() {},
    updateJson(json) {
      this.json = json
    },
  },
  data() {
    return {
      keyword: '',
      logDialog: false,
      userDialog: false,
      type: 'detail',
      types: [{ text: 'ข้อความรายงาน', value: 'detail' }],
      modify: 'add',
      loading: false,
      show1: false,
      show2: false,
      json: null,
    }
  },
  watch: {},
  components: {
    arrestTable,
    arrestDialog,
    arrestShow,
    logDlg,
  },
}
</script>

<style></style>
